import React from "react"
import Helmet from "react-helmet"
import Uninstall from "@tightrope/footerlinks/uninstall"
import HomepageLayout from "src/layouts/homepage"
import JSONData from "branding/site-metadata.json"

export default function UninstallPage() {
  return(

    <HomepageLayout>
        <Helmet data={JSONData}>
        <style type="text/css">{`
       
       .footerlinks-module--footerlinksContainer {
        position:relative;
        padding:0 0 100px 0;
      }

    `}
    </style>
          <title>Uninstall - frompdftodoc.com</title></Helmet>
    <Uninstall>    <Helmet>Test</Helmet></Uninstall>
    </HomepageLayout>
  )
}
